import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export interface ICreateUserDialog {
    open : boolean;
    groupList : { label: string, value: string}[];
    cancelAction : () => void;
    createAction : (givenName : string, familyName : string, email : string, groupName : string) => void;
}

function UserCreateDialog(props : ICreateUserDialog) {
    const { t } = useTranslation();
    
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');
    const [groupName, setGroupName] = useState('');

    const [givenNameError, setGivenNameError] = useState(true);
    const [familyNameError, setFamilyNameError] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [groupNameError, setGroupNameError] = useState(true); 
    

    const handleGivenName = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setGivenName(value);
        setGivenNameError(value === undefined || value === '');
    }

    const handleFamilyName = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setFamilyName(value ?? '');
        setFamilyNameError(value === undefined || value === '');
    }

    const handleGroup = (value : string | undefined) => {
        setGroupName(value ?? '');
        setGroupNameError(value === undefined || value === '');
    }

    const handleEmail = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const regEx = /^[a-zA-Z0-9.\-_]+@(?:[a-zA-Z0-9\-_]+\.)+[A-Za-z]+$/;
        let value = e.target.value;
        setEmail(value ?? '');
        setEmailError(value === undefined || value === ''|| !regEx.test(value));
    }

    const hasErrors = useMemo(() => {
        return givenNameError || familyNameError || emailError;
    }, [givenNameError, familyNameError, emailError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form to create a new user. Once the user got created he/ she will receive an invitation e-mail.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                        type="text"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('First Name')}
                                        onChange={e => handleGivenName(e)}
                                        value={givenName}
                                        error={givenNameError}
                                        helperText={givenNameError ? 'Please enter the firstname' : ''}
                                        fullWidth
                                        size="small"
                            />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                        type="text"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Last Name')}
                                        onChange={e => handleFamilyName(e)}
                                        value={familyName}
                                        error={familyNameError}
                                        helperText={familyNameError ? 'Please enter the lastname' : ''}
                                        fullWidth
                                        size="small"
                            />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                            <TextField
                                        type="email"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('E-Mail')}
                                        onChange={e => handleEmail(e)}
                                        value={email}
                                        error={emailError}
                                        helperText={emailError ? 'Please enter the email address.' : ''}
                                        fullWidth
                                        size="small"
                            />
                    </FormControl>  
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="group-label">Group</InputLabel>
                        <Select
                            labelId="group-label"
                            id="group-select"
                            value={groupName}
                            error={groupNameError}
                            label="Type"
                            onChange={(e) => handleGroup(e.target.value) }>
                                { props.groupList && props.groupList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(givenName, familyName, email, groupName)}>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default UserCreateDialog;