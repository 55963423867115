
import ListSubheader from '@mui/material/ListSubheader';
import NavigationItem from './NavigationItem';
import { INavigationGroup, INavigationItem } from './NavigationItems';
import { useAuth } from '../hooks/useAuth';
import { SOURCE_MARKETS } from '../common/paths';

export interface INavigationGroupProps extends INavigationGroup {
}

function NavigationItemGroup(props: INavigationGroupProps) {
    const { label, children } = props;
    const auth = useAuth();
    
    const isVisible = (item : INavigationItem) => {
        
        let result = ((item.administration ?? false) === false && (item.superadmin ?? false) === false) || ((item.administration ?? true) && auth.isAdministrator && (item.superadmin ?? false) === false) 
        || auth.isSuperAdmin;
        
        if(item.path === SOURCE_MARKETS) {
            result = result || result;
        }
        return result;
    }

    return (
        <>
            <ListSubheader>{label}</ListSubheader>
            {children.filter(o => isVisible(o)).map((item) => <NavigationItem key={item.id} {...item} />)}
        </>
    );
}

export default NavigationItemGroup;