import { useState, useEffect, ChangeEvent } from 'react'
import { Stack, Button, Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CatererFields, UserFields, UserMembership } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import { useAuth } from '../../hooks/useAuth';

export interface IUserMembershipDialogProps {
    open : boolean;
    user? : UserFields;
    supplierList? : (CatererFields & DynamoDbObject)[];
    cancelAction : () => void;
    createAction : (user : UserFields) => void;
}

function UserMembershipDialog(props : IUserMembershipDialogProps) {
    const [ memberships, setMemberships] = useState<UserMembership[]>([]);
    const auth = useAuth();
    
    useEffect(() => {
        setMemberships(props.user?.Memberships ?? []);
    }, [props.user]);

    const onChange = (catererId : string, catererName : string, event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const item = memberships.find(o => o.CatererId === catererId);

        if(checked && item === undefined) {
            const item : UserMembership = {
                Username: props.user?.Username ?? '',
                CatererName: catererName,
                CatererId: catererId
            }

            setMemberships([...memberships, item]);
        }

        if(!checked && item !== undefined) {
            setMemberships(memberships.filter(o => o.CatererId !== catererId));
        }
    }

    const onSave = () => {
        let item = props.user;
        if(item === undefined)
            return;

        item!.Memberships = memberships;
        props.createAction(item!);
    }
    
    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Supplier Belonging</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select one or more supplier to grant the user access to flights which will be managed by these supplier. 
                </DialogContentText>
                <Box>
                <Stack spacing={0} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    { props.supplierList?.map((o : CatererFields & DynamoDbObject) => 
                        <FormGroup>
                            <FormControlLabel control={<Checkbox disabled={!auth.isSuperAdmin} value={o.RK} onChange={(e, c) => onChange(o.RK, o.Name ?? 'Unkown', e, c)} checked={memberships.find(m => m.CatererId === o.RK) !== undefined} />} label={o.Name} />
                        </FormGroup> 
                    )} 
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                { auth.isSuperAdmin && <Button color="primary" onClick={(e) => onSave()}>Save</Button> }
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default UserMembershipDialog;