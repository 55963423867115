import { useState, useMemo, useCallback, useEffect } from 'react'
import { API } from 'aws-amplify';
import { Drawer, Box, SvgIcon, IconButton, CircularProgress, Button, ListItemText, MenuItem, Select, OutlinedInput, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { DynamoDbObject } from '@aviation/catering-common';
import { CatererFields, CatererSubscription } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import dayjs,  {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { Status, useFetch } from '../../hooks/useFetch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ulid } from 'ulidx';

dayjs.extend(utc)

export interface ISupplierSubscriptionListProps {
    open: boolean;
    clientCode : string;
    sourceMarketList : {label: string, value: string}[];
    parent: CatererFields & DynamoDbObject
    cancelAction: () => void;
}

function rowId(item : any) {
    return item?.SubscriptionId;
}

function SupplierSubscriptionList(props : ISupplierSubscriptionListProps) {
    const apiRef = useGridApiRef();
    const { status, data = [] } = useFetch<CatererSubscription[]>(`/api/masterdata/caterer/${props.parent.RK}/subscription`, o => o);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [subscriptionList, setSubscriptionList] = useState<CatererSubscription[]>([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);

    const convertToDate = (hour : number, minutes : number) => {
        let date = dayjs.utc().hour(hour).minute(minutes).second(0);
        return date;
    }

    const defaultDaysOfWeek = () => {
        return ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    }

    useEffect(() => {
        setSubscriptionList(data);
    }, [data]);

    const getFixedRecipients = useCallback((list : (string | undefined)[]) => {
        // We use undefined as item to display (All) in dropdown. When we get 
        // data from database, it gets converted into null
        if(!list)
            return [undefined];

        let result = list.filter(o => o !== null);
        if(result.length === 0)
            result.push(undefined);

        return result;
    },[])

    const rows = useMemo(() => {
        const result = subscriptionList.map((entry: CatererSubscription) => {
            return {
                ...entry,
                Time: convertToDate(entry.ScheduledOn?.Hours ?? 0, entry.ScheduledOn?.Minutes ?? 0).local(),
                DayOfWeek: entry.ScheduledOn?.DayOfWeek ?? defaultDaysOfWeek(),
                id: rowId(entry),
                isNew: (entry as any).isNew ?? false,
                Recipients: getFixedRecipients(entry.Recipients ?? [])
            }
        });
        return result;
        // eslint-disable-next-line
    }, [subscriptionList])

    const userList = useMemo(() => {
        return [{ value: undefined, label: '(All)' }, ...props.parent.Users?.map(u =>{ return { value: u.Username, label: u.Username ?? '(Unkown)' }}) ?? []];
    }, [props.parent]);

    const weekDays = useMemo(() => {
        return [
            { value: 'MON', label: 'Monday' },
            { value: 'TUE', label: 'Tuesday' },
            { value: 'WED', label: 'Wednesday' },
            { value: 'THU', label: 'Thursday' },
            { value: 'FRI', label: 'Friday' },
            { value: 'SAT', label: 'Saturday' },
            { value: 'SUN', label: 'Sunday' },
        ];
    }, [])

    const fileTypes = useMemo(() => {
        return [
            { value: 'csv', label: 'CSV' },
            { value: 'pdf', label: 'PDF' },
        ];
    }, [])

    const reportTypes = useMemo(() => {
        return [
            { value: 'full', label: 'Full' },
            { value: 'minimized', label: 'Minimized' },
        ];
    }, [])

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
          });

        const editedRow = rows.find((row : any) => row.id === id);
        if (editedRow!.isNew) {
            const rowList = subscriptionList.filter((o : any) => o.SubscriptionId !== id) 
            setSubscriptionList(rowList);
        } 
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [props.parent]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [props.parent]);

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };

        updateItem(row as CatererSubscription & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to subscription saved successfully.`);
            else
                toast.error(`An error occurred while saving subscription`);
        })
        
        return updatedRow;
        // eslint-disable-next-line
    }, [props.parent]);

    const updateItem = async(item : any) => {
        if(!item)
            return;

        const time = (item.Time as Dayjs).utc();
        
        if(!item.ScheduledOn) {
            item.ScheduledOn = {DayOfWeek: item.DayOfWeek, Hours: time.hour(), Minutes: time.minute()};
        } else {
            
            item.ScheduledOn.Hours = time.hour();
            item.ScheduledOn.Minutes = time.minute();
            item.ScheduledOn.DayOfWeek = item.DayOfWeek;
        }

        let payload = item;
        delete payload.id;
        
        const init = {
            body: payload,
            headers: {}
        };

        try {
            const response = await API.put('api', `/api/masterdata/caterer/${(props.parent as any).id}/subscription`, init) as CatererSubscription;
            return response;
        } catch(e) {
            console.error(e);
            return undefined;
        } 
    }

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            const newList = subscriptionList.filter(o => o.SubscriptionId !== deleteItem.SubscriptionId);

            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);
            
            const init = {
                body: {},
                headers: {}
            };

            try {
                const response = await API.del('api', `/api/masterdata/caterer/${(props.parent as any).id}/subscription/${deleteItem.ClientCode}/${deleteItem.SubscriptionId}`, init) as CatererSubscription;
                toast.success(`Subscription deleted successfully.`);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                setSubscriptionList(newList);
                return response;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting subscription`);
                return undefined;
            } 
        }
    };

    const columns: GridColDef[] = [
        { field: 'ClientCode', headerName: 'Source Market', minWidth: 140, type: 'singleSelect', editable: true, valueOptions: props.sourceMarketList },
        { field: 'FileType', headerName: 'File type', minWidth: 100, type: 'singleSelect', editable: true, valueOptions: fileTypes },
        { field: 'ReportType', headerName: 'Report type', minWidth: 100, type: 'singleSelect', editable: true, valueOptions: reportTypes },
        { field: 'Time', headerName: 'Time', minWidth: 110, editable: true, renderEditCell(params) {
            return (<LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker value={params.row.Time} format="HH:mm" onChange={(value, c) => {
                    const id = params.id;
                    const field = params.field; 
                    apiRef.current.setEditCellValue({id, field, value});
                 }} />
            </LocalizationProvider>)
        }, renderCell: ({ row }: Partial<GridRowParams>) => { 
            let date = row.Time as Dayjs;

            if(date !== undefined)
                return date.format('HH:mm');// `${date.hour()}:${date.minute()}`;
            else
                return '';
        }},
        { field: 'DayOfWeek', headerName: 'Days', minWidth: 250,
            renderEditCell(params) {
                return (
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected as any).join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {weekDays.map((listItem) => (
                            <MenuItem key={listItem.value} value={listItem.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(listItem.value) > -1} onChange={e => {
                                if(e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list : string[] = [].concat(params.row.DayOfWeek) ?? []; 
                                    const idx = list.indexOf(listItem.value);

                                    if(idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    } else {
                                        list.push(listItem.value);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    }
                                    
                                }
                            }}/>
                            <ListItemText primary={listItem.label} />
                            </MenuItem>
                        ))}
                        </Select>
                )
            }, editable: true },
            { field: 'Recipients', headerName: 'Recipients', minWidth: 250,
            renderCell(params) {
                return (params.value?.length === 1 && (params.value[0] === undefined || params.value[0] === null)) ? '(All)' : (params.value as any).join(', ')
            },
            renderEditCell(params) {
                return (
                    <Select
                        labelId="recipient-multiple-checkbox-label"
                        id="recipient-multiple-checkbox"
                        multiple
                        fullWidth
                        value={params.value}
                        onChange={(e, c) => {
                            params.value = e.target.value
                        }
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (selected.length === 1 && (selected[0] === undefined || selected[0] === null)) ? '(All)' : (selected as any).join(', ')}
                        //MenuProps={MenuProps}
                        >
                        {userList.map((listItem) => (
                            <MenuItem key={listItem.value} value={listItem.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(listItem.value) > -1} onChange={e => {
                                if(e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    let list : (string | undefined)[] = [].concat(params.row.Recipients) ?? []; 
                                    let idx = list.indexOf(listItem.value);                                    

                                    if(idx !== -1) {
                                        list.splice(idx, 1);

                                        // Add (All), when nothing is added
                                        if(list.length === 0)
                                            list.push(undefined);

                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    } else {
                                        // When (All) got enabled, then we have to remove all other selections!
                                        if(listItem.value === undefined) {
                                            list = [];
                                        } else {
                                            // When we add other items, then we have to remove (All)
                                            list = list.filter(o => o !== undefined);
                                        }

                                        

                                        list.push(listItem.value);
                                        apiRef.current.setEditCellValue({id, field, value: list});
                                    }
                                    
                                }
                            }}/>
                            <ListItemText primary={listItem.label} />
                            </MenuItem>
                        ))}
                        </Select>
                )
            }, editable: true },
        { field: 'DaysInAdvance', headerName: 'Days in advance', minWidth: 120, type: 'number', editable: true },
        { field: 'DurationInDays', headerName: 'Days to include', minWidth: 120, type: 'number', editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isInEditMode && !isLoading)
                return ( 
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const createItem = async () => {
        const id = ulid().toString();
        const row = {
            SubscriptionId: id,
            CatererId: (props.parent as any).id,
            CatererName: props.parent.Name,
            ClientCode: props.clientCode,
            FileType: 'csv',
            ReportType: 'full',
            DaysInAdvance: 1,
            DurationInDays: 1,
            ScheduledOn: {
                DayOfWeek: defaultDaysOfWeek(),
                Hours: 9,
                Minutes: 0
            },
            isNew: true
        } as CatererSubscription;
        
        //props.parent.Entries = [...props.parent.Entries!, row];
        const list = [...subscriptionList, row];
        setSubscriptionList(list);
        setRowModesModel((oldModel) => ({...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'FileType' } }));
    }

    return (
        <Drawer anchor='right' open={props.open} onClose={props.cancelAction} style={{maxWidth:'50%'}}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Subscriptions'}>
                    <Button variant="contained" onClick={createItem}>Create</Button>
                </BoxHeader>
            </Box>
            <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    loading={status === Status.Fetching || status === Status.Idle}
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : ''}}
                    // pagination
                    isCellEditable={(params) => {
                        if(params.field === 'ClientCode') {
                            return params.row.isNew;
                        }

                        return true;
                    }}

                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete subscription?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this subscription? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
        </Drawer>
    )
}

export default SupplierSubscriptionList