import { Box, Button, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { ServiceTypeFields, AircraftTypeFields, HaulTypeFields, SsrCodeFields } from '@aviation/catering-masterdata-sdk';
import { useMemo, useState } from 'react';
import ServiceTypeSsrGrid from './ServiceTypeSsrGrid';
import ServiceTypeCrewGrid from './ServiceTypeCrewGrid';
import { toast } from 'react-toastify';
import { API } from 'aws-amplify';
import ServiceTypeCreateDialog from './ServiceTypeCreateDialog';
import { ulid } from 'ulidx';

function ServiceTypes() {
    const { clientCode } = useParams();
    const { data: airlineData = [] } = useFetch<Array<AircraftTypeFields & DynamoDbObject>>(`/api/masterdata/airline/`, o => o);
    const { data: haulTypeData = [] } = useFetch<Array<HaulTypeFields & DynamoDbObject>>(`/api/masterdata/haultype/${clientCode}`, o => o.Items);
    const { data: ssrCodeData = [] } = useFetch<Array<SsrCodeFields & DynamoDbObject>>(`/api/masterdata/ssrcode/${clientCode}`, o => o.Items);
    const { data: aircraftData = [] } = useFetch<Array<SsrCodeFields & DynamoDbObject>>(`/api/masterdata/aircraftregistration`);
    const { data = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${clientCode}`);
    const [ loading ] = useState(false);
    const [ showCreateDialog, setShowCreateDialog] = useState(false);
    const [ tabIndex, setTabIndex] = useState(0);
   
    const rowId = (row : any) : string => {
        return `${row.PK}#${row.RK}`;
    }

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? o.RK ?? 'Unkown', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const ssrCodeList = useMemo(() => {
        return ssrCodeData.filter(o => !(o.IsProduct ?? false)).map(o => { return {label: o.RK ?? 'Unkown', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [ssrCodeData])

    const haulTypeList = useMemo(() => {
        return haulTypeData.sort((a,b) => (a.SortIndex ?? 0) < (b.SortIndex ?? 0) ? -1 : 1).map(o => { return {label: o.Name ?? 'Unkown', value: o.RK.split('#')[1], airline: o.RK.split('#')[0] } });
    }, [haulTypeData])

    const aircraftList = useMemo(() => {
        return aircraftData.map(o => { return {label: o.RK.split('#')[1] ?? 'Unkown', value: o.RK.split('#')[1], airline: o.RK.split('#')[0] } });
    }, [aircraftData])

    const rows = useMemo(() => {
        return data.filter(o => tabIndex === 0 ? (o.Type === 'SSR') : (o.Type === 'CREW')).map(o => { return {...o, id: rowId(o), airline: o.RK.split('#')[0], SsrCodes: o.SsrCodes ?? [], HaulTypes: o.HaulTypes ?? [], AcRegs: o.AcRegs ?? [], Functions: o.Functions ?? [] }}).sort((a,b) => (a.RK ?? '') < (b.RK ?? '') ? -1 : 1);
    }, [data, tabIndex])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const onUpdate = async (item : ServiceTypeFields & DynamoDbObject) => {
        let success = await updateItem(item);

        if (success)
            toast.success(`Changes to service type saved successfully.`);
        else
            toast.error(`An error occurred while saving service type`);

        return true;
    }

    const onDelete = async(item : ServiceTypeFields & DynamoDbObject) => {
        let success = await deleteItem(item);

        if (success)
            toast.success(`Service type deleted successfully.`);
        else
            toast.error(`An error occurred while deleting service type`);

        return true;
    }

    const onCreateItem = async(airline : string, type: ("CREW" | "SSR"), code : string) => {
        setShowCreateDialog(false);

        let item : ServiceTypeFields & DynamoDbObject = {
            PK: `${clientCode}#ServiceType`,
            RK: `${airline}#${ulid().toString()}`,
            AcRegs: [],
            Code: code,
            Type: type,
            CsvHeader: '',
            SsrCodes: []
        }

        let result = await updateItem(item);
        if(result) 
            toast.success(`Service type has been created successfully.`);
        else
            toast.error(`An error occurred while creating a new service type.`);

        return result;
    }

    const deleteItem = async (item : ServiceTypeFields & DynamoDbObject) => {
        const init = {
            body: {},
            headers: {}
        };

        try {
            await API.del('api', `/api/masterdata/servicetype/${clientCode}/${item.RK.split('#')[0]}/${item.RK.split('#')[1]}`, init);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const updateItem = async (item : ServiceTypeFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/servicetype/${clientCode}/${item.RK.split('#')[0]}/${item.RK.split('#')[1]}`, init);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, paddingBottom: 0, display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={3}>
                    <Box sx={{flexGrow: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}>
                        <Typography variant="subtitle1" component="h2" sx={{ textTransform: 'uppercase' }}>
                            Service Types
                        </Typography>
                        <Stack direction='row' spacing={2} style={{float:'right', marginTop:-20}}>
                            <Button variant="contained" onClick={() => setShowCreateDialog(true) }>Create</Button>
                        </Stack>
                    </Box>
                    <div style={{flexBasis:'100%', flexGrow:1}} />
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="SSR" />
                        <Tab label="Crew" />
                    </Tabs>
                </Paper>
                
                
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    { tabIndex === 0 && <ServiceTypeSsrGrid airlineList={airlineList} filteredData={rows} loading={loading} haulTypeList={haulTypeList} ssrCodeList={ssrCodeList} aircraftList={aircraftList} updateAction={onUpdate} deleteAction={onDelete} />}
                    { tabIndex === 1 && <ServiceTypeCrewGrid airlineList={airlineList} filteredData={rows} loading={loading} updateAction={onUpdate} deleteAction={onDelete} />}
                    { airlineList && <ServiceTypeCreateDialog open={showCreateDialog} airlineList={airlineList} cancelAction={() => setShowCreateDialog(false) } createAction={onCreateItem} type={tabIndex === 0 ? 'SSR' : 'CREW' }/>}
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ServiceTypes;