import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',    
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          'required_attributes_name': 'Name',
          'StartNotInCateringGroup': 'The system could not find any station group for departure airport of first leg.',
          'UndeterminableHaulType': 'The system could not calculate proper haul type. Please select haul type manually.',
          'MultipleOutstationsNoPrio': 'There are multiple outstations without or the same priority. Please check your configuration',
          'DestinationDoesNotMatchNextDeparture': 'The system detected a gap between two legs. Destination station doesn\'t equal departure station of next flight',
          'DepartureDoesNotMatchLastDestination': 'The system detected a gap between two legs. Departure station doesn\'t equal destination station of previous flight',
          'DepartureEqualsDestination': 'Departure and destination are the same for one flight at least.',
          'NoLoadingPlan': 'Could not assign loading plan. Please assign a loading plan manually.',
          'MultipleLoadingPlans': 'Could not assign loading plan, because there were multiple options.',
          'NoCaterer': 'At least one leg misses a caterer. Please assign a caterer to these legs.',
          'Ignored': 'This route has been flagged to be ignored.',
          'Swap': 'Caterer of the starting airport is different from the final airport.',
          'RouteNotFinished': 'Station group of first or last leg is not the same or could not be found. This warning can also indicate that we miss a flight, so that destination and departure of consecutive legs are differnt.',
          'Title-StartNotInCateringGroup': 'No station group',
          'Title-UndeterminableHaulType': 'No haul type',
          'Title-MultipleOutstationsNoPrio': 'Multiple outstations',
          'Title-DestinationDoesNotMatchNextDeparture': 'Destination doesn\'t match',
          'Title-DepartureDoesNotMatchLastDestination': 'Departure doesn\'t match',
          'Title-DepartureEqualsDestination': 'Departure = destination',
          'Title-NoLoadingPlan': 'No loading plan',
          'Title-MultipleLoadingPlans': 'Multiple loading plans',
          'Title-NoCaterer': 'No Caterer',
          'Title-Ignored': 'Ignored',
          'Title-Swap': 'Caterer swap',
          'Title-RouteNotFinished': 'Route incomplete'
        }
      }
    }
  });

export default i18n;