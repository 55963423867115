import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export interface IRouteOverrideCreateEntryDialog {
    open : boolean;
    airlineList : {label: string, value: string}[];
    catererList : {label: string, value: string}[];
    haulTypeList : {label: string, value: string}[];
    loadingPlanList : {label: string, value: string}[];
    cancelAction : () => void;
    createAction : (airline : string, route : string, loadingPlanId : string, haultype : string, isDutyFree : boolean, catererId : string, outstationCatererId : string) => void;
}

function RouteOverrideCreateEntryDialog(props : IRouteOverrideCreateEntryDialog) {
    const { t } = useTranslation();
    
    const [ airlineCode, setAirlineCode] = useState('');
    const [ route, setRoute] = useState('');
    const [ haulType, setHaulType] = useState('');
    const [ loadingPlan, setLoadingPlan] = useState('');
    const [ isDutyFree, setIsDutyFree] = useState(false);
    const [ caterer, setCaterer] = useState('');
    const [ outstationCaterer, setOutstationCaterer] = useState('');
    
    const [ airlineCodeError, setAirlineCodeError] = useState(true);
    const [ routeError, setRouteError] = useState(true);
    const [ haulTypeError, setHaulTypeError] = useState(true);
    const [ loadingPlanError, setLoadingPlanError] = useState(true);
    const [ catererError, setCatererError] = useState(true);

    const hasErrors = useMemo(() => {
        return airlineCodeError || routeError || haulTypeError || loadingPlanError || catererError 
    }, [airlineCodeError, routeError, haulTypeError, loadingPlanError, catererError]);

    const haulTypeList = useMemo(()=> (props.haulTypeList ?? []).filter(x=>x.value.split('#')[0] === airlineCode).map((o)=> (
        <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
    )), [props.haulTypeList, airlineCode])

    const loadingPlanList = useMemo(()=> (props.loadingPlanList ?? []).filter(x=>x.value.split('#')[0] === airlineCode).map((o)=> (
        <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
    )), [props.loadingPlanList, airlineCode])


    const handleAirlineCode = (value : string | undefined) => {
        setAirlineCode(value ?? '');
        handleHaulType('')
        handleLoadingPlan('')
        setAirlineCodeError(value === undefined || value === '');
    }  

    const handleRoute = (value : string | undefined) => {
        setRoute(value ?? '');
        setRouteError(!/^[A-Z]{3}-[A-Z]{3}(-[A-Z]{3})*$/g.test(value ?? ''));
    }   

    const handleIsDutyFree = () => {
        const value = !isDutyFree;
        setIsDutyFree(value);
    }   

    const handleHaulType = (value : string | undefined) => {
        setHaulType(value ?? '');
        setHaulTypeError(value === undefined || value === '');
    }   

    const handleLoadingPlan = (value : string | undefined) => {
        setLoadingPlan(value ?? '');
        setLoadingPlanError(value === undefined || value === '');
    }   

    const handleCaterer = (value : string | undefined) => {
        setCaterer(value ?? '');
        setCatererError(value === undefined || value === '');
    }   

    const handleOutstationCaterer = (value : string | undefined) => {
        setOutstationCaterer(value ?? '');
    }   

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Exception Route</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are going to create a new Route Exception to specify specific route configurations that are not handled by the default logic.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="airline-label">Airline</InputLabel>
                        <Select
                            labelId="airline-label"
                            id="airline-select"
                            value={airlineCode}
                            error={airlineCodeError}
                            label="Type"
                            onChange={(e) => handleAirlineCode(e.target.value) }>
                                { props.airlineList && props.airlineList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Route')}
                                    onChange={e => handleRoute(e.target.value)}
                                    value={route}
                                    error={routeError}
                                    helperText={routeError ? 'Please provide a route in the format MUC-PMS-MUC, all upper case, at least two stations' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="haulType-label">Haul Type</InputLabel>
                        <Select
                            labelId="haulType-label"
                            id="haulType-select"
                            value={haulType}
                            error={haulTypeError}
                            label="Type"
                            onChange={(e) => handleHaulType(e.target.value) }>
                                { haulTypeList }
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="loadingPlan-label">Loading Plan</InputLabel>
                        <Select
                            labelId="loadingPlan-label"
                            id="loadingPlan-select"
                            value={loadingPlan}
                            error={loadingPlanError}
                            label="Type"
                            onChange={(e) => handleLoadingPlan(e.target.value) }>
                                { loadingPlanList }
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel required control={<Checkbox value={isDutyFree} onChange={() => handleIsDutyFree()} />} label="Is Duty Free" />
                    </FormGroup>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="caterer-label">Supplier</InputLabel>
                        <Select
                            labelId="caterer-label"
                            id="caterer-select"
                            value={caterer}
                            error={catererError}
                            label="Type"
                            onChange={(e) => handleCaterer(e.target.value) }>
                                { props.catererList && props.catererList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                    <InputLabel id="outstationCaterer-label">Outstation Supplier</InputLabel>
                    <Select
                            labelId="outstationCaterer-label"
                            id="outstationCaterer-select"
                            value={outstationCaterer}
                            label="Type"
                            onChange={(e) => handleOutstationCaterer(e.target.value) }>
                                <MenuItem  key={undefined} value={undefined}>None</MenuItem>
                                { props.catererList && props.catererList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, route, loadingPlan.split('#')[1], haulType.split('#')[1], isDutyFree, caterer, outstationCaterer) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default RouteOverrideCreateEntryDialog;