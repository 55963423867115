import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Paper, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@mui/material';
// import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { Status, useFetch } from '../../hooks/useFetch';
import { DynamoDbObject } from '@aviation/catering-common';
import { AircraftGroupFields, AirlineFields, BobConfigurationFields, SsrCodeFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as PlanIcon } from '../../icons/notepad.svg';
import BobForecastCreateEntryDialog from './BobForecastCreateEntryDialog';
import { toast } from "react-toastify";
import BobForecastDetails from './BoBForecastDetails';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import BobForecastCopyDialog from './BobForecastCopyDialog';
import BobForecastRoutePackagePlan from './BoBForecastRoutePackagePlan';

export interface IBobForecastRoutesProps {
    open: boolean;
    clientCode: string;
    showCreateDialog: boolean;
    cancelAction: () => void;
}

function BobForecastRoutes(props: IBobForecastRoutesProps) {
    const apiRef = useGridApiRef();

    const [refreshCounter, setRefreshCounter] = useState(0);
    const { status, data = [] } = useFetch<Array<BobConfigurationFields & DynamoDbObject>>(`/api/masterdata/bobconfiguration/${props.clientCode}`, (response) => response.Items, refreshCounter);
    // const { data: packagePlanData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/bobpackageplan/${props.clientCode}`);
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${props.clientCode}`);
    const { data: productData = [] } = useFetch<Array<SsrCodeFields & DynamoDbObject>>(`/api/masterdata/ssrcode/${props.clientCode}`);
    const { data: aircraftTypesData = [] } = useFetch<Array<string>>(`/api/masterdata/aircrafttype/`, o => o);
    const { data: aircraftGroupData = [] } = useFetch<Array<AircraftGroupFields>>(`/api/masterdata/aircraftgroup/`, o => o.Items);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<BobConfigurationFields & DynamoDbObject | undefined>(undefined);
    const [showPackagePlanAnchor, setShowPackagePlanAnchor] = useState(false);
    const [showDetailsAnchor, setShowDetailsAnchor] = useState(false);
    const [showCopyDialogAnchor, setShowCopyDialogAnchor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentParent, setCurrentParent] = useState<any>(undefined);

    const rowId = (row: any) => {
        return `${row.PK}#${row.RK}`;
    }

    // const packagePlanList = useMemo(() => {
    //     return packagePlanData.map(o => { return { label: (o as any).Name, value: (o as any).Id } }).sort((a, b) => a.label < b.label ? -1 : 1);
    // }, [packagePlanData])

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return { label: (o as any).Description ?? '', value: o.RK } }).sort((a, b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const productList = useMemo(() => {
        return productData.filter(o => o.IsProduct === true).map(o => { return { label: (o.RK + ' - ' + o.Description ?? ''), value: o.RK } }).sort((a, b) => a.label < b.label ? -1 : 1);
    }, [productData])

    const acTypeList = useMemo(() => {
        return aircraftTypesData.map(o => { return { label: o, value: o } }).sort((a, b) => a.label < b.label ? -1 : 1);
    }, [aircraftTypesData])

    const acGroupList = useMemo(() => {
        return aircraftGroupData.map(o => { return {label: o.Name ?? '(Unkown)', value: o.Id ?? '' } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [aircraftGroupData])

    const getActivePackagePlans = (item : BobConfigurationFields | undefined) => {
        if(item?.Plans === undefined) 
            return '';

        const now = new Date().toISOString();

        const filtered = item.Plans.filter(o => {
            return (o.ValidFrom === undefined || o.ValidFrom === null || o.ValidFrom <= now) &&
                (o.ValidTo === undefined || o.ValidTo === null || o.ValidTo >= now); 
        })

        const set = new Set<string>();
        filtered.forEach(o => set.add(o.Name ?? '(Unkown)'));

        return Array.from(set).sort((a,b) => a < b ? -1 : 1).join(', ');
    }

    const rows = useMemo(() => {
        if (!data) return [];
        const result = (data as any[]).reduce((a, b) => [
            ...a,
            {
                ...b,
                id: rowId(b),
                Al: b.RK.split('#')[0],
                Route: b.RK.split('#')[1],
                Dep: b.RK.split('#')[1].split('-')[0],
                Dest: b.RK.split('#')[1].split('-')[1],
                // Entries: b.Entries,
                PackingPlan: getActivePackagePlans(b)
            }
        ], []);

        return result;
    }, [data])

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item: any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };
    const handleCopyItem = async (airline: string, dep: string, dest: string, packingPlan: string) => {
        setShowCopyDialogAnchor(false)
        setLoading(true)

        const init = {
            body: {
                SourcePK: currentParent.PK,
                SourceRK: currentParent.RK,
                TargetDep: dep,
                TargetDest: dest,
                PackingPlan: packingPlan ?? undefined
            },
            headers: {}
        };
        try {
            await API.post('api', `/api/masterdata/bobconfiguration/copy`, init);
            toast.success(`Bob Configuration copyied successfully`);
        } catch (e) {
            console.error(e);
            toast.error(`An error occurred while copying BoB configuration`);
            return false;
        } finally {
            setRefreshCounter(refreshCounter + 1)
            setLoading(false)
        }
    }

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if (deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };

            try {
                await API.del('api', `/api/masterdata/bobconfiguration/${props.clientCode}/${(deleteItem as any).Al}/${deleteItem.RK.split('#')[1]}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`BoB configuration deleted successfully.`);
                return true;
            } catch (e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting BoB configuration`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const handleSaveClick = (item: any) => () => {
        setRowModesModel({ ...rowModesModel, [item.id ?? '']: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
    }, [data]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
        // eslint-disable-next-line
    }, [data]);


    const updateItem = async (item: BobConfigurationFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/bobconfiguration/${props.clientCode}/${item.RK.replace('#', '/')}`, init);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };

        updateItem({ PK: row.PK, RK: row.RK, PackingPlan: row.PackingPlan }).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if (success)
                toast.success(`Changes to Bob Configuration saved successfully.`);
            else
                toast.error(`An error occurred while saving Bob Configuration`);
        })

        return updatedRow;
    }

    const createForecastEntry = (airline: string, dep: string, dest: string) => {
        setLoading(true);

        const item: BobConfigurationFields & DynamoDbObject = {
            PK: `${props.clientCode}#BobConfiguration`,
            RK: `${airline}#${dep.toUpperCase()}-${dest.toUpperCase()}`,
            Entries: []
        }

        updateConfiguration(item).then((result: boolean) => {
            setLoading(false);

            if (result) {
                (item as any).id = rowId(item);
                (item as any).id = rowId(item);
                (item as any).Al = airline;
                (item as any).Dep = dep;
                (item as any).Dest = dest;
                apiRef.current.updateRows([item]);
                toast.success('BoB forecast created successfully.');
            }
            else
                toast.error('An error occurred while creating BoB forecast');
        });
    }

    const updateConfiguration = async (item: any) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/bobconfiguration/${props.clientCode}/${item.RK.split('#')[0]}/${item.RK.split('#')[1]}`, init);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    // const recalculateAll = async () => {
    //     setLoading(true);
    //     const promises: Promise<boolean>[] = []

    //     for(var i = 0; i < (rows.length ?? 0); i++) {
    //         const item = rows[i];
    //         promises.push(updateConfiguration(item));
    //     }

    //     try {
    //         await Promise.all(promises);
    //         toast.success('BoB forecast recalculation has been triggered successfully.');
    //     } catch(error) {
    //         toast.error('An unkown error occurred.');
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const columns: GridColDef[] = [
        { field: 'Al', headerName: 'Airline', minWidth: 150, editable: false, type: 'singleSelect', valueOptions: airlineList },
        { field: 'Dep', headerName: 'Dep', minWidth: 80 },
        { field: 'Dest', headerName: 'Dest', minWidth: 70, editable: false },
        { field: 'PackingPlan', headerName: 'Packing Plan', minWidth: 70, editable: false, type: 'string' },
        { field: 'Entries', headerName: 'Items', minWidth: 80, valueGetter: (params) => params.row.Entries.length, editable: false },
        {
            field: 'actions', type: 'actions', headerName: '', minWidth: 160, renderCell: ({ row }: Partial<GridRowParams>) => {
                const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
                const isLoading = row.IsLoading ?? false;

                if (isInEditMode)
                    return [
                        <IconButton color="success" onClick={handleSaveClick(row)}>
                            <SvgIcon component={CheckmarkIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="error" onClick={handleCancelClick(row.id)}>
                            <SvgIcon component={CrossIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (!isLoading)
                    if (!isInEditMode) return [
                        <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowDetailsAnchor(true); }}>
                            <SvgIcon component={EditIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowPackagePlanAnchor(true); }}>
                            <SvgIcon component={PlanIcon} inheritViewBox />
                        </IconButton>,
                        // <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowCopyDialogAnchor(true); }}>
                        //     <SvgIcon component={ThumbnailsIcon} inheritViewBox />
                        // </IconButton>,
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>

                    ]
                    else
                        return (
                            <IconButton color="error" onClick={() => confirmDeletion(row)}>
                                <SvgIcon component={TrashIcon} inheritViewBox />
                            </IconButton>
                        )
                else if (isLoading)
                    return (
                        <CircularProgress size={19} />
                    )
            }
        }
    ];
    return (
        <Grid item xs={12}>
            <Grid>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}

                            autoHeight
                            rows={rows}
                            columns={columns}
                            editMode="row"

                            // getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            getRowClassName={(params) => { return params.row.Parent === false ? `tui-grid-leg-row` : 'tui-grid-route-row' }}
                            // pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            isCellEditable={(params) => true}

                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}

                            groupingColDef={{
                                headerName: ' ',
                                valueGetter: (params: GridValueGetterParams) => '',
                                width: 50,
                                resizable: false,
                                hideable: false,
                            }}
                        />
                    </StyledBox>
                    {props.showCreateDialog && (<BobForecastCreateEntryDialog
                        open={props.showCreateDialog}
                        airlineList={airlineList}
                        // selectedDestinations={apiRef.current.rows}
                        cancelAction={props.cancelAction}
                        createAction={createForecastEntry}
                    />)}
                    {currentParent && showCopyDialogAnchor && <BobForecastCopyDialog open={showCopyDialogAnchor} parent={currentParent} cancelAction={() => { setShowCopyDialogAnchor(false); }} copyAction={handleCopyItem} />}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Configuration?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete this BoB configuration? You can't undo this action.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
            {currentParent && <BobForecastDetails open={showDetailsAnchor} clientCode={props.clientCode} parent={currentParent} productList={productList} aircraftTypeList={acTypeList} aircraftGroupList={acGroupList} cancelAction={() => { setShowDetailsAnchor(false); }} updateAction={() => { }} />}
            {currentParent && <BobForecastRoutePackagePlan open={showPackagePlanAnchor} clientCode={props.clientCode} parent={currentParent} cancelAction={() => { setShowPackagePlanAnchor(false); }} />}
        </Grid>
    )
}

export default BobForecastRoutes;