import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface ICreateSourceMarketDialog {
    open : boolean;
    cancelAction : () => void;
    createAction : (code : string, name : string) => void;
}

function SourceMarketCreateDialog(props : ICreateSourceMarketDialog) {
    const { t } = useTranslation();
    
    const [ ssrCode, setSsrCode] = useState('');
    const [ name, setName] = useState('');
    
    const [ ssrCodeError, setSsrCodeError] = useState(true);
    const [ nameError, setNameError] = useState(true);
    

    const handleCode = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setSsrCode(value);
        setSsrCodeError(value === undefined || value === '');
    }

    const handleName = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setName(value ?? '');
        setNameError(value === undefined || value === '');
    }

    const hasErrors = useMemo(() => {
        return ssrCodeError || nameError;
    }, [ssrCodeError, nameError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Source Market</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide a code and a name to create a new source market. After you've created the new source market,
                    please ensure to add airlines to the source market, to map flights to it. 
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                        type="text"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Code')}
                                        onChange={e => handleCode(e)}
                                        value={ssrCode}
                                        error={ssrCodeError}
                                        helperText={ssrCodeError ? 'Please enter a code' : ''}
                                        fullWidth
                                        size="small"
                            />
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Name')}
                                    onChange={e => handleName(e)}
                                    value={name}
                                    error={nameError}
                                    helperText={nameError ? 'Please enter a name' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(ssrCode.toUpperCase(), name)}>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default SourceMarketCreateDialog;