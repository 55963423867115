import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { Paper, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Box, Typography, Stack, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Status, useFetch } from '../../hooks/useFetch';
import { useLocation, useParams } from 'react-router-dom';
import { AircraftGroupFields, AircraftRegistrationFields, AircraftTypeFields, SourceMarketFields, SourceMarketMapping } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import {toast} from "react-toastify";
import AircraftCreateDialog from './AircraftCreateDialog';

function Aircrafts() {
    const apiRef = useGridApiRef();
    const location = useLocation();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<AircraftRegistrationFields & DynamoDbObject>>(`/api/masterdata/aircraftregistration`);
    const { data: aircraftTypesData = [] } = useFetch<Array<string>>(`/api/masterdata/aircrafttype/`, o => o);
    const { data: airlineData = [] } = useFetch<Array<AircraftTypeFields & DynamoDbObject>>(`/api/masterdata/airline/`, o => o);
    const { data: aircraftGroupData = [] } = useFetch<Array<AircraftGroupFields>>(`/api/masterdata/aircraftgroup/`);
    const { data: sourceMarketData = [] } = useFetch<Array<SourceMarketFields>>(`/api/masterdata/sourcemarkets/all`);
    
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<AircraftRegistrationFields & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [currentSourceMarket, setCurrentSourceMarket] = useState<SourceMarketFields | undefined>(undefined);

    const rowId = (row : any) : string => {
        return `${row.PK}#${row.RK}`;
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        let pageState = { tabIndex: newValue };
        localStorage.setItem(`PageState_${location.pathname}`, JSON.stringify(pageState));
        setTabIndex(newValue);
    };

    const handleTabClick = (clientCode : string | undefined) => {

        if(clientCode)  {
            let item = sourceMarketData.find(o => o.ClientCode === clientCode);
            setCurrentSourceMarket(item);
        }
        else
            setCurrentSourceMarket(undefined);
    }

    const otherSourceMarkets = useMemo(() => {
        let list : SourceMarketMapping[] = [];

        data.forEach(o => {
            let airline = o.RK.split('#')[0];

            if(!sourceMarketData.find(m => m.Airlines?.find(a => a.Airline === airline)) && !list.find(a => a.Airline === airline))
                list.push({
                    Airline: airline
                });
        })

        let result : SourceMarketFields = {
            Airlines: list,
            ClientCode: 'OTHER',
            ClientName: 'Other'
        }
        return result;
    }, [data, sourceMarketData])

    const handleFallbackTab = useCallback(() => {
        setCurrentSourceMarket(otherSourceMarkets);
    }, [otherSourceMarkets])

    const rows = useMemo(() => {
        return data.filter(o => {
            return currentSourceMarket === undefined || currentSourceMarket.Airlines?.find(a => a.Airline === o.RK.split('#')[0]);
        }).map(o => { return {...o, id: rowId(o), airline: o.RK.split('#')[0], FixedFlightCrew: o.FixedFlightCrew ?? 0, FixedCabinCrew: o.FixedCabinCrew ?? 0 }}).sort((a,b) => (a.RK ?? '') < (b.RK ?? '') ? -1 : 1);
    }, [data, currentSourceMarket])

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? o.RK ?? 'Unkown', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const acTypeList = useMemo(() => {
        return aircraftTypesData.map(o => { return {label: o, value: o } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [aircraftTypesData])

    const acGroupList = useMemo(() => {
        return aircraftGroupData.map(o => { return {label: o.Name ?? '(Unkown)', value: o.Id } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [aircraftGroupData])

    const columns: GridColDef[] = [
        { field: 'airline', headerName: 'Airline', minWidth: 150, editable: false, 
            type: 'singleSelect', valueOptions: airlineList },
        { field: 'Name', headerName: 'Name', minWidth: 150, valueGetter: (params) => params.row.RK.split('#')[1] },
        { field: 'AircraftType', headerName: 'Type', minWidth: 150, editable: true,
            type: 'singleSelect', valueOptions: acTypeList },
        { field: 'AcGrpId', headerName: 'Group', minWidth: 150, editable: true,
            type: 'singleSelect', valueOptions: acGroupList },
        { field: 'PaxCapacity', headerName: 'Capacity', minWidth: 90, editable: false, type:'number'},
        { field: 'FixedFlightCrew', headerName: 'Cockpit Crew', minWidth: 90, editable: true, type:'number'},
        { field: 'FixedCabinCrew', headerName: 'Cabin Crew', minWidth: 90, editable: true, type:'number'},
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;
    
            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/aircraftregistration/${deleteItem.RK.replace('#', '/')}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Aircraft deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting aircraft`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const updateItem = async(item : AircraftRegistrationFields & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/aircraftregistration/${item.RK.replace('#', '/')}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const onCreateItem = async (airlineCode : string, code : string, acType : string) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : AircraftRegistrationFields & DynamoDbObject = {
            AircraftType: acType,
            PK: `${clientCode}#AircraftRegistration`,
            RK: `${airlineCode}#${code}`,
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
       
        if(await updateItem(item)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`Aircraft added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while creating aircraft`);
        }
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as AircraftRegistrationFields & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to aircraft saved successfully.`);
            else
                toast.error(`An error occurred while saving aircraft`);
        })
        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {/* <PageHeader title={t('Aircrafts')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid> */}

            {/* Header with Tabs */}
            <Paper sx={{ p: 2, paddingBottom: 0, display: 'flex', flexWrap: 'wrap', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }} elevation={3}>
                    <Box sx={{flexGrow: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}>
                        <Typography variant="subtitle1" component="h2" sx={{ textTransform: 'uppercase' }}>
                        {t('Aircrafts')}
                        </Typography>
                        <Stack direction='row' spacing={2} style={{float:'right', marginTop:-20}}>
                            <Button variant="contained" onClick={() => setShowCreateDialog(true) }>Create</Button>
                        </Stack>
                    </Box>
                    <div style={{flexBasis:'100%', flexGrow:1}}>
                        
                    </div>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="All" onClick={(e) => handleTabClick(undefined)}/>
                        { sourceMarketData.map(o => (<Tab label={o.ClientName} value={o.ClientCode} onClick={(e) => handleTabClick(o.ClientCode)}/>))}
                        <Tab label="Other" onClick={(e) => handleFallbackTab()}/>
                    </Tabs>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            isCellEditable={() => true }
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    <AircraftCreateDialog open={showCreateDialog} airlineList={airlineList} acTypeList={acTypeList} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem}/>
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Aircraft?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this aircraft? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Aircrafts;