import { useMemo, useState } from 'react'
import { API } from 'aws-amplify';
import { styled, Button, Stack, SvgIcon, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stepper, StepLabel, Step, CircularProgress } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';
import dayjs from 'dayjs';
import { AirlineFields, CatererFields, HaulTypeFields, LoadingPlanFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import CreateRouteDialogProperties from './CreateRouteDialogProperties';
import { CateringRouteFields, CateringRouteLeg } from '@aviation/catering-cateringrouteservice-sdk';
import { ulid } from 'ulidx';
import { Status, useFetch } from '../../../hooks/useFetch';
import CreateRouteDialogFlights from './CreateRouteDialogFlights';
import CreateRouteDialogSummary from './CreateRouteDialogSummary';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export interface ICreateRouteStep {
    id: number;
    key: string;
    label: string;
    valid: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <SvgIcon component={CrossIcon} inheritViewBox />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface ICreateRouteDialogProps {
    clientCode: string;
    cancelAction: () => void;
    createAction: () => void;
}

export function CreateRouteDialog(props: ICreateRouteDialogProps) {
    const navigate = useNavigate();
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: airlineStatus, data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${props.clientCode}`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: loadingPlanStatus, data: loadingPlanData = [], error: loadingPlanError } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/loadingplan/${props.clientCode}`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: haulTypeStatus, data: haulTypesData = [], error: haulTypesError } = useFetch<Array<HaulTypeFields & DynamoDbObject>>(`/api/masterdata/haultype/${props.clientCode}`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: catererStatus, data: catererData = [], error: catererError } = useFetch<Array<CatererFields & DynamoDbObject>>(`/api/masterdata/caterer`); // TODO: handle status
    const { status: serviceTypeStatus, data: serviceTypeData = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${props.clientCode}`);
    const [ isUpdatingRoute, setIsUpdatingRoute] = useState(false);

    const initialSteps : ICreateRouteStep[] = [
        { id: 0, key: 'properties', label: 'Properties', valid: false},
        { id: 1, key: 'flights', label: 'Flights', valid: false},
        { id: 2, key: 'finish', label: 'Finish', valid: false}
    ];

    // const [displayAllFlights, setDisplayAllFlights] = useState(false);
    // const [selectedFlights, setSelectedFlights] = useState<Leg[] | undefined>(undefined);
    // // const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([props.dateFrom ?? dayjs(), props.dateTo ?? dayjs().add(1, 'day')]);
    // const [hasFlights, setHasFlights] = useState(false);
    // const [filterTerm, setFilterTerm] = useState('');
    const [stepList, setStepList] = useState(initialSteps);
    const [activeStep, setActiveStep] = useState<ICreateRouteStep>(stepList[0]);

    const [route, setRoute] = useState<CateringRouteFields & DynamoDbObject>({ 
        PK: ulid().toString(),
        RK: 'CateringRoute',
        FlightDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        ClientCode: props.clientCode,
        AggregatedWarnings: undefined,
        Warnings: [],
        IsDutyFree: false,
        HaulType: undefined,
        Legs: []
    });
    
    const isLoading = useMemo(() => {
        return airlineStatus !== Status.Fetched || haulTypeStatus !== Status.Fetched || loadingPlanStatus !== Status.Fetched || catererStatus !== Status.Fetched || serviceTypeStatus !== Status.Fetched;
    }, [airlineStatus, haulTypeStatus, loadingPlanStatus, catererStatus, serviceTypeStatus])

    const handleClose = () => {
        props.cancelAction();
    }

    const handleCreate = async () => {
        setIsUpdatingRoute(true);

        const routeToString = (legs: CateringRouteLeg[]) => `${legs.map(x => x.Dep).join('-')}-${legs[legs.length - 1].Dest}`;

        route.Route = routeToString(route.Legs ?? []);
        route.FlightNumbers = Array.from(new Set(route.Legs?.map(o => `${o.Al}${o.Nr}`))).join(', ');
        route.LoadingStations = Array.from(new Set(route.Legs?.map(o => o.LoadingStations))).join(', ');
        route.IsLocked = true;

        const init = {
            body: route,
            headers: {}
        };

        try {
            
            await API.put('api', `/api/cateringroutes/byroute/${route.PK}`, init);
            toast.success(`Catering route created successfully.`);
            navigate(`${route.PK}`)
        } catch(e) {
            console.error(e);
            toast.error(`An error occurred while creating catering route.`);
        } finally {
            setIsUpdatingRoute(false);
        }
    }

    const validationExecuted = (item : CateringRouteFields & DynamoDbObject, valid : boolean) => {
        const newState = { ...activeStep, valid: valid };
        setActiveStep(newState);
        let list = stepList;
        list[activeStep.id] = newState;
        setStepList(list);
        setRoute(item);
    }

    const nextStep = () => {
        if(activeStep.key === 'finish') {
            handleCreate();
        } else {
            setActiveStep(stepList[activeStep.id + 1]);
        }
    }

    const previousStep = () => {
        if(activeStep.id === 0)
            return;

        setActiveStep(stepList[activeStep.id - 1]);
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                maxWidth='lg'
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Stack>
                        <p style={{paddingTop:0, marginTop:0}}>Create Route</p>
                        <Stack direction='row' useFlexGap flexWrap="wrap" spacing={1}>
                            <Stepper activeStep={activeStep.id}>
                            {
                                stepList.map(s => (
                                    <Step key={s.key}><StepLabel>{s.label}</StepLabel></Step>
                                ))
                            }
                            </Stepper>
                        </Stack>

                    </Stack>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    { isLoading && <div style={{display: 'flex', justifyContent: 'center', padding:40}}><CircularProgress size={25}/></div> }
                    { !isLoading && activeStep.key === 'properties' && airlineData && <CreateRouteDialogProperties airlineData={airlineData} haulTypesData={haulTypesData} loadingPlanData={loadingPlanData} catererData={catererData} clientCode={props.clientCode} route={route} validationExecuted={validationExecuted} />}
                    { !isLoading && activeStep.key === 'flights' && airlineData && <CreateRouteDialogFlights airlineData={airlineData} clientCode={props.clientCode} route={route} validationExecuted={validationExecuted} />}
                    { !isLoading && activeStep.key === 'finish' && airlineData && <CreateRouteDialogSummary serviceTypeData={serviceTypeData} airlineData={airlineData} haulTypesData={haulTypesData} loadingPlanData={loadingPlanData} catererData={catererData} clientCode={props.clientCode}  route={route} validationExecuted={validationExecuted} />}
                </DialogContent>
                <DialogActions>
                    { !isUpdatingRoute && <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button> }
                    { !isUpdatingRoute && activeStep.id > 0 && <Button autoFocus onClick={previousStep}>
                        Back
                    </Button> }
                    
                    { !isUpdatingRoute && <Button autoFocus onClick={nextStep} disabled={!activeStep.valid}>
                        { activeStep.key !== 'finish' ? 'Next' : 'Create' }
                    </Button> }
                    { isUpdatingRoute && (<CircularProgress size={25} />)}
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

export default CreateRouteDialog;