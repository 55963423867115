import { useState, useMemo } from 'react'
import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, useGridApiRef, GridRowModesModel, GridRowModes, GridRowParams, GridRowModel, GridEventListener, GridRowEditStopReasons, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { Paper, SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Tooltip, Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Status, useFetch } from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { AircraftGroupFields, AirlineFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import {toast} from "react-toastify";
import VipAlertCreateDialog from './VipAlertCreateDialog';
import { VipAlertConfigItem } from '@aviation/vip-alert-service-sdk';
import { ulid } from 'ulidx';
import VipAlertDetails from './VipAlertDetails';

function VipAlert() {
    const apiRef = useGridApiRef();
    const { t } = useTranslation();
    const { clientCode } = useParams();
    const { status, data = [] } = useFetch<Array<VipAlertConfigItem & DynamoDbObject>>(`/api/vipalert/${clientCode}`, o => o);
    const { data: aircraftGroupData = [] } = useFetch<Array<AircraftGroupFields>>(`/api/masterdata/aircraftgroup/`);
    const { data: airlineData = [] } = useFetch<Array<AirlineFields & DynamoDbObject>>(`/api/masterdata/airline/${clientCode}`); 
    const { data: serviceTypeData = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${clientCode}`);
    
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<VipAlertConfigItem & DynamoDbObject | undefined>(undefined);
    const [currentParent, setCurrentParent] = useState<VipAlertConfigItem & DynamoDbObject | undefined>(undefined);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [loading, setLoading] = useState(false);

    const rowId = (row : any) : string => {
        return row.Id;
    }

    const airlineList = useMemo(() => {
        return airlineData.map(o => { return {label: (o as any).Description ?? '', value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [airlineData])

    const acGroupList = useMemo(() => {
        return aircraftGroupData.map(o => { return {label: o.Name ?? '(Unkown)', value: o.Id ?? '' } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [aircraftGroupData])

    const serviceTypeList = useMemo(() => {
        let result : any[] = [];

        if(airlineData !== undefined) {
            // Build unique list of service types for each airline
            airlineData.forEach(a => { 
                const list = new Set(serviceTypeData.filter(t => { return t.RK.split('#')[0] === a.RK && t.Type === 'SSR' }).map(t => t.Code));
                result = result.concat(...Array.from(list).map(o => { return { label: o, value: o, airline: a.RK }}).sort((a,b) => (a.label ?? '') < (b.label ?? '') ? -1 : 1));
            });
        }
        
        return result;
    }, [serviceTypeData, airlineData])

    const rows = useMemo(() => {
        return data.map(o => { return {
                ...o, 
                id: rowId(o) 
            }}).sort((a,b) => (a.RK ?? '') < (b.RK ?? '') ? -1 : 1);
    }, [data]);

    const recipientList = useMemo(() => {
        return (currentParent?.Recipients ?? []).map(o => {
            return {
                Recipient: o,
                id: ulid().toString()
            }
        })
    }, [currentParent]);

    const columns: GridColDef[] = [
        { field: 'Airline', headerName: 'Airline', minWidth: 200, type: 'singleSelect', valueOptions: airlineList },
        { field: 'AcGrpId', headerName: 'Aircraft Group', minWidth: 200, editable: true, type: 'singleSelect', valueOptions: acGroupList },
        { field: 'ServiceTypes', headerName: 'Service Types', minWidth: 150, editable: true, renderEditCell(params) {
            return (
                <Select
                    labelId="function-checkbox-label"
                    id="function-checkbox"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        let selectedLabels = serviceTypeList.filter(o => selected.some((i : string) => i === o.value && o.airline === params.row.Airline)).map(o => o.label).join(', ');
                        return selectedLabels;
                    }}
                >
                    {serviceTypeList.filter(o => o.airline === params.row.Airline).map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(type.value) > -1} onChange={e => {
                                if (e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list: string[] = [].concat(params.row.Functions) ?? [];
                                    const idx = list.indexOf(type.value);

                                    if (idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    } else {
                                        list.push(type.value);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    }

                                }
                            }} />
                            <ListItemText primary={type.label} />
                        </MenuItem>
                    ))}
                </Select>
            )
        }, renderCell: (params) => {
            let selected = params.value ?? [];
            let selectedLabels = serviceTypeList.filter(o => selected.some((i : string) => i === o.value && o.airline === params.row.Airline)).map(o => o.label).join(', ');
            return selectedLabels;
        } },
        // { field: 'Capacity', headerName: 'Capacity', minWidth: 100, type: 'number', editable: true },
        { field: 'ThresholdDash', headerName: 'Thres. Dashboard', minWidth: 150, type: 'number', editable: true },
        { field: 'ThresholdNotify', headerName: 'Thres. Notify', minWidth: 150, type: 'number', editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[rowId(row)]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(rowId(row))}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return [
                    <Tooltip title="Source Markets">
                        <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowDetails(true); }}>
                            <SvgIcon component={SettingsIcon} inheritViewBox />
                        </IconButton>
                    </Tooltip>,
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [rowId(item) ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/vipalert/${clientCode}/${deleteItem.AcGrpId}/${deleteItem.Airline}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`VIP number deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting VIP number`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const updateItem = async(item : VipAlertConfigItem) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/vipalert/${clientCode}/${item.AcGrpId}/${item.Airline}`, init);
            let parent = {...(item as VipAlertConfigItem & DynamoDbObject)};
            setCurrentParent(parent);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const onCreateItem = async (airline : string, acGrpId : string, serviceTypes : string[], thresDash : number, thresNotify : number) => {
        setShowCreateDialog(false);
        setLoading(true);

        const item : VipAlertConfigItem = {
            Id: ulid().toString(),
            ClientCode: clientCode,
            Airline: airline,
            AcGrpId: acGrpId,
            ServiceTypes: serviceTypes,
            Capacity: 0,
            ThresholdDash: thresDash,
            ThresholdNotify: thresNotify,
            Recipients: [],
            TemplateSubject: 'VIP Capacity Exceeded {FlightNumbers} - {Route} {FlightDate}',
            Template: 'Dear, \r\n\r\nAccording to the meal order for the below flight, we have exceeded the standard maximum amount of VIP capacity for the concerning aircraft.\r\n\r\n{FlightDetails}\r\n\r\nKindly regards,\r\n\r\nYour TUI Catering Team'
        };

        // Define id and airline for grid usage
        (item as any).id = rowId(item);
        (item as any).airline = airline;
       
        if(await updateItem(item)) {
            setLoading(false);
            apiRef.current.updateRows([item]);
            toast.success(`VIP number added successfully`);
        } else {
            setLoading(false);
            toast.error(`An error occurred while VIP number`);
        }
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem(row as VipAlertConfigItem).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to VIP number saved successfully.`);
            else
                toast.error(`An error occurred while saving VIP number`);
        })
        // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('VIP Numbers')}>
                    <Button variant="contained" onClick={() => setShowCreateDialog(true)}>Create</Button>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                        <DataGridPremium
                            apiRef={apiRef}
                            autoHeight 
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            pagination
                            loading={status === Status.Fetching || status === Status.Idle || loading}
                            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
                            isCellEditable={() => true }
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </StyledBox>
                    { currentParent && <VipAlertDetails updateAction={(item) => updateItem(item)} recipientList={recipientList} open={showDetails} parent={currentParent} cancelAction={() => {setShowDetails(false);}} />}
                    { serviceTypeList && (<VipAlertCreateDialog acGroupList={acGroupList} serviceTypeList={serviceTypeList} airlineList={airlineList} cancelAction={() => setShowCreateDialog(false)} createAction={onCreateItem} open={showCreateDialog} />)}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete VIP number?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this VIP number? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default VipAlert;