import { useState, useMemo } from 'react'
import { Stack, Button, Box, Checkbox, ListItemText, OutlinedInput, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export interface IVipAlertCreateDialog {
    open: boolean;
    airlineList: { label: string, value: string }[];
    serviceTypeList: { label: string, value: string, airline: string }[];
    acGroupList: { label: string, value: string }[];
    cancelAction: () => void;
    createAction: (airline: string, acGrpId: string, serviceTypes: string[], thresDash : number, thresNotify : number) => void;
}

function VipAlertCreateDialog(props: IVipAlertCreateDialog) {
    const [airlineCode, setAirlineCode] = useState('');
    const [acGrpId, setAcGrpId] = useState<string | undefined>(undefined);
    const [loadingTypes, setLoadingTypes] = useState<string[]>([]);
    const [thresDash, setThresDash] = useState(0);
    const [thresNotify, setThresNotify] = useState(0);

    const [airlineCodeError, setAirlineCodeError] = useState(true);
    const [acGrpIdError, setAcGrpIdError] = useState(true);
    const [loadingTypesError, setLoadingTypesError] = useState(true);

    const handleAirlineCode = (value: string | undefined) => {
        setAirlineCode(value ?? '');
        setAirlineCodeError(value === undefined || value === '');
    }

    const handleAcGroup = (value: string) => {
        setAcGrpId(value);
        setAcGrpIdError(value === undefined || value === '');
    }

    const hasErrors = useMemo(() => {
        return airlineCodeError || loadingTypesError || acGrpIdError;
    }, [airlineCodeError, loadingTypesError, acGrpIdError]);

    return (
        <div>
            <Dialog open={props.open} onClose={props.cancelAction}>
                <DialogTitle>Create VIP Number</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill out the form to create new thresholds for VIP numbers.
                    </DialogContentText>
                    <Box>
                        <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop: 2 }}>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <InputLabel id="airline-label">Airline</InputLabel>
                                <Select
                                    labelId="airline-label"
                                    id="airline-select"
                                    value={airlineCode}
                                    error={airlineCodeError}
                                    label="Type"
                                    onChange={(e) => handleAirlineCode(e.target.value)}>
                                    {props.airlineList && props.airlineList.map((o) => (
                                        <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <InputLabel id="acgrp-label">Aircraft Group</InputLabel>
                                <Select
                                    labelId="acgrp-label"
                                    id="acgrp-select"
                                    value={acGrpId}
                                    error={acGrpIdError}
                                    label="Type"
                                    onChange={(e) => handleAcGroup(e.target.value)}>
                                    {props.acGroupList && props.acGroupList.map((o) => (
                                        <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <InputLabel id="loadingTypes-label">Service Types</InputLabel>
                                <Select
                                    labelId="loadingTypes-label"
                                    id="loadingTypes-checkbox"
                                    multiple
                                    fullWidth
                                    error={loadingTypesError}
                                    value={loadingTypes}
                                    onChange={(e, c) => {

                                    }
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => (selected as any).join(', ')}
                                //MenuProps={MenuProps}
                                >
                                    {props.serviceTypeList.filter(o => {
                                        return o.airline === airlineCode
                                    }).map((serviceType) => (
                                        <MenuItem key={serviceType.value} value={serviceType.value}>
                                            <Checkbox checked={(loadingTypes ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                                                if (e !== undefined) {
                                                    let list: string[] = [];
                                                    list = list.concat(loadingTypes);
                                                    const idx = list.indexOf(serviceType.value);

                                                    if (idx !== -1) {
                                                        list.splice(idx, 1);
                                                        setLoadingTypes(list);
                                                        setLoadingTypesError(list.length === 0);
                                                    } else {
                                                        list.push(serviceType.value);
                                                        setLoadingTypes(list);
                                                        setLoadingTypesError(list.length === 0);
                                                    }

                                                }
                                            }} />
                                            <ListItemText primary={serviceType.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Stack spacing={{ sm: 2 }} direction="row" useFlexGap>
                            <FormControl sx={{ minWidth: 220 }}>
                                <TextField id="thresdash-input" label="Threshold Dashboard" type='number' size="small" value={thresDash} onChange={(e) => setThresDash(parseInt(e.target.value))} />
                            </FormControl>
                            <FormControl sx={{ minWidth: 220 }}>
                                <TextField id="thresnotify-input" label="Threshold Notification" type='number' size="small" value={thresNotify} onChange={(e) => setThresNotify(parseInt(e.target.value))} />
                            </FormControl>
                            
                            </Stack>
                            
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.cancelAction}>Cancel</Button>
                    <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, acGrpId ?? '', loadingTypes, thresDash, thresNotify)}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default VipAlertCreateDialog;