import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { CLIENT_CODE, STORAGE_KEY__CLIENT_CODE } from "../constants";
import { useLocalStorage } from "usehooks-ts";

type Props = {
    children?: React.ReactNode;
};

const AppParamProvider: React.FC<Props> = ({ children }) => {
    const { isAuthenticated, isAdministrator, isSuperAdmin, isUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { clientCode } = useParams();
    const [selectedClientCode, setSelectedClientCode] = useLocalStorage(STORAGE_KEY__CLIENT_CODE, clientCode || CLIENT_CODE);
  
    useEffect(() => {
        const defaultPath = `/${selectedClientCode}`;
        if (isAuthenticated && (!clientCode) && (isSuperAdmin || isAdministrator || isUser)) {
            setSelectedClientCode(selectedClientCode);
            console.info('no client code or airline selected... navigating to default route:', defaultPath)
            navigate(defaultPath, { state: location, replace: true });
        }
    }, [location, navigate, isAuthenticated, isSuperAdmin, isAdministrator, isUser, selectedClientCode, setSelectedClientCode, clientCode])

    return <>{children}</>;
};

export default AppParamProvider;