import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { SvgIcon } from '@mui/material';
import { ReactComponent as ChevronTopIcon } from '../icons/chevron--top.svg';
import { ReactComponent as ChevronDownIcon } from '../icons/chevron--down.svg';
import { INavigationItem } from './NavigationItems';
import { useLocation } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';
import { SOURCE_MARKETS } from '../common/paths';

export interface INavigationItemProps extends INavigationItem {
}

function NavigationItem(props: INavigationItemProps) {
    const { icon, label, children, path, rootPath } = props;
    const location = useLocation();
    const fullPath = path ? `${rootPath}/${path}` : rootPath;
    const selected = location.pathname.startsWith(fullPath);
    const auth = useAuth();
    
    const isVisible = (item : INavigationItem) => {
        let result = ((item.administration ?? false) === false && (item.superadmin ?? false) === false) || ((item.administration ?? true) && auth.isAdministrator && (item.superadmin ?? false) === false) 
        || auth.isSuperAdmin; 
        
        if(item.path === SOURCE_MARKETS) {
            result = result || result;
        }
        return result;
    }

    return (
        <>
            <ListItemButton href={fullPath} selected={location.pathname === fullPath}>
                {icon ? <ListItemIcon>
                    <SvgIcon component={icon} inheritViewBox />
                </ListItemIcon> : null}
                <ListItemText primary={label} />
                {children ? (selected ?
                    <SvgIcon component={ChevronTopIcon} inheritViewBox /> :
                    <SvgIcon component={ChevronDownIcon} inheritViewBox />
                ) : null}
            </ListItemButton>
            {children ? <Collapse in={selected} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {children.filter(o => isVisible(o)).map(({ id, label, path }) => {
                        const childPath = fullPath + path;
                        return (
                            <ListItemButton href={childPath} key={id} sx={{ pl: 8 }} selected={location.pathname === childPath}>
                                <ListItemText primary={label} />
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse> : null}
        </>
    );
}

export default NavigationItem;