import { Chip } from "@mui/material";
import { useMemo } from "react";
import dayjs from "dayjs";


function VipAlertChip(item : any) {
    const isDashNotify = useMemo(() => {
        return item.item.ThresholdNotifyOn === undefined;
    }, [item])

    const text = useMemo(() => {
        return dayjs(item.item.ThresholdNotifyOn ?? item.item.ThresholdDashOn).format('YYYY-MM-DD HH:mm');
    }, [item])

    return (
        <div>
            { !isDashNotify && <Chip size='small' key='incomplete' label={text} color="error" /> }
            { isDashNotify && <Chip size='small' label={text} color="warning" /> }
        </div>
        
    )
}

export default VipAlertChip;