import { useState, useEffect } from 'react'
import { Drawer, Box, SvgIcon, IconButton, CircularProgress, Button, FormControl, TextField, Stack, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';
import { VipAlertConfigItem } from '@aviation/vip-alert-service-sdk';
import { ulid } from 'ulidx';

export interface IVipAlertProps {
    open: boolean;
    parent: VipAlertConfigItem & DynamoDbObject
    recipientList: { Recipient: string, id: string }[];
    cancelAction: () => void;
    updateAction: (item: VipAlertConfigItem) => Promise<boolean>;
}

function VipAlertDetails(props: IVipAlertProps) {
    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any | undefined>(undefined);
    const [content, setContent] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [changed, setChanged] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        setRows(props.recipientList);
    }, [props.recipientList]);

    useEffect(() => {
        setChanged(false);
        setContent(props.parent.Template ?? '');
        setSubject(props.parent.TemplateSubject ?? '');
    }, [props.parent])

    const handleChange = (type : string, value : string) => {
        switch(type) {
            case 'Subject':
                setChanged(true);
                setSubject(value);
                break;

            case 'Content':
                setChanged(true);
                setContent(value);
                break;
        }
    }

    const handleSaveClick = (item: any) => () => {
        setRowModesModel({ ...rowModesModel, [item.id ?? '']: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row: any) => row.id === id);
        if ((editedRow as any)!.isNew) {
            const rowList = rows.filter((o: any) => o.id !== id)
            setRows(rowList);
        }
    };

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else
            setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };

        updateItem(row).then(success => {
            apiRef.current.updateRows([{ id: row.id, IsLoading: false }]);

            if (success)
                toast.success(`Changes to VIP number saved successfully.`);
            else
                toast.error(`An error occurred while saving VIP number`);
        })

        return updatedRow;
        // eslint-disable-next-line
    }

    const updateItem = async (item: any) => {
        let payload = props.parent;
        payload.Recipients = [...props.recipientList.filter(o => o.id !== item.id).map(p => p.Recipient), item.Recipient];        

        return await props.updateAction(payload);
    }

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: deleteItem.id, IsLoading: true }]);

            let payload = props.parent;
            payload.Recipients = rows.filter(o => o.id !== deleteItem.id).map(o => o.Recipient);
    
            const success =  await props.updateAction(payload);
            if (success) {
                //apiRef.current.updateRows([{ id: deleteItem.id, _action: 'delete' }]);
                toast.success(`Changes to VIP number saved successfully.`);
            }
            else {
                //apiRef.current.updateRows([{ id: deleteItem.id, IsLoading: false }]);
                toast.error(`An error occurred while saving VIP number`);
            }
                
        }
    };

    const updateMailTemplate = async () => {
        setChanged(false);
        let payload = props.parent;
        payload.Template = content;
        payload.TemplateSubject = subject;
        const success =  await props.updateAction(payload);
        if (success)
            toast.success(`Changes to VIP number saved successfully.`);
        else
            toast.error(`An error occurred while saving VIP number`);
    }

    // const createItem = useCallback(async () => {
    //     let id = ulid().toString();
    //     let item = {
    //         id: id,
    //         Recipient: '',
    //         isNew: true
    //     };

    //     setRows([...rows, item]);
    //     apiRef.current.updateRows([item]);
    //     setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Recipient' } }));
    // }, [rows, apiRef]);
    const createItem = async () => {
        let id = ulid().toString();
        let item = {
            id: id,
            Recipient: '',
            isNew: true
        };

        setRows([...rows, item]);
        apiRef.current.updateRows([item]);
        setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Recipient' } }));
    }

    const columns: GridColDef[] = [
        { field: 'Recipient', headerName: 'E-Mail Address', minWidth: 200, type: 'string', editable: true, preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const address = params.props.value;
            const regEx = /^[a-zA-Z0-9.\-_]+@(?:[a-zA-Z0-9\-_]+\.)+[A-Za-z]+$/;
            const hasError = !regEx.test(address);
            return { ...params.props, error: hasError };
          } },
        {
            field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
                const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
                const isLoading = row.IsLoading ?? false;

                if (isInEditMode)
                    return [
                        <IconButton color="success" onClick={handleSaveClick(row)}>
                            <SvgIcon component={CheckmarkIcon} inheritViewBox />
                        </IconButton>,
                        <IconButton color="error" onClick={handleCancelClick(row.id)}>
                            <SvgIcon component={CrossIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (!isInEditMode && !isLoading)
                    return [
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    ]
                else if (isLoading)
                    return (
                        <CircularProgress size={19} />
                    )
            }
        }
    ];

    return (
        <Drawer anchor='right' open={props.open} onClose={(e, r) => { props.cancelAction(); }} style={{ maxWidth: '50%' }}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight: 20 }}>
                <BoxHeader title={'Recipients'}>
                    <Button variant="contained" onClick={createItem}>Create</Button>
                </BoxHeader>
            </Box>
            <StyledBox style={{ paddingLeft: 20, paddingRight: 20 }}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : '' }}
                    // pagination
                    isCellEditable={(params) => true}

                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                />
            </StyledBox>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight: 20 }}>
                <BoxHeader title={'E-Mail'}>
                </BoxHeader>
            </Box>
            <Box style={{ marginLeft: 20, marginRight:20 }}>
                <Stack direction="column" spacing={2} >
                    <FormControl>
                        <TextField label="Subject" size="small" value={subject} onChange={(e) => handleChange('Subject', e.target.value ?? '')} />
                    </FormControl>
                    <FormControl>
                        <TextField label="Content" multiline rows={8} size="small" style={{minWidth:600}} value={content} onChange={(e) => handleChange('Content', e.target.value ?? '')} />
                    </FormControl>
                    <Grid container justifyContent="flex-end">
                        <Button disabled={!changed} variant="contained" onClick={(e) => updateMailTemplate()}>Save</Button>
                    </Grid>
                </Stack>

            </Box>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete VIP number?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this VIP number? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
        </Drawer>
    )
}

export default VipAlertDetails