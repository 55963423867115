
import { useMemo, useState } from 'react';
import { DataGridPremium, GridColDef, GridRowParams, GridRowModesModel, GridRowModes, GridEventListener, GridCallbackDetails, GridRowEditStopReasons, GridRowModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { SvgIcon, IconButton, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import StyledBox from '../../common/Components/StyledBox';

export interface IServiceTypeSsrGridProps {
    filteredData : (ServiceTypeFields & DynamoDbObject)[];
    airlineList : {label: string, value: string}[];
    haulTypeList : {label: string, value: string, airline: string}[];
    aircraftList : {label: string, value: string, airline: string}[];
    ssrCodeList : {label: string, value: string}[];
    loading : boolean;
    updateAction? : (item :ServiceTypeFields & DynamoDbObject) => Promise<boolean>;
    deleteAction? : (item :ServiceTypeFields & DynamoDbObject) => Promise<boolean>;
}

function ServiceTypeSsrGrid(props : IServiceTypeSsrGridProps) {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<(ServiceTypeFields & DynamoDbObject) | undefined>(undefined);
    const apiRef = useGridApiRef();

    const columns: GridColDef[] = [
        { field: 'airline', headerName: 'Airline', minWidth: 150, editable: false, type: 'singleSelect', valueOptions: props.airlineList },
        { field: 'Code', headerName: 'Code', minWidth: 50, editable: true },
        { field: 'CsvHeader', headerName: 'CSV Header', minWidth: 150, editable: true },
        { field: 'SortIndex', headerName: 'Sort Index', minWidth: 100, editable: true },
        { field: 'Priority', headerName: 'Priority', minWidth: 100, editable: true },
        { field: 'SsrCodes', headerName: 'Ssr Codes', minWidth: 200, renderEditCell(params) {
            return (
                <Select
                    labelId="ssr-checkbox-label"
                    id="ssr-checkbox"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        let selectedLabels = props.ssrCodeList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
                        return selectedLabels;
                    }}
                >
                    {props.ssrCodeList.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(type.value) > -1} onChange={e => {
                                if (e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list: string[] = [].concat(params.row.SsrCodes) ?? [];
                                    const idx = list.indexOf(type.value);

                                    if (idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    } else {
                                        list.push(type.value);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    }

                                }
                            }} />
                            <ListItemText primary={type.label} />
                        </MenuItem>
                    ))}
                </Select>
            )
        }, editable: true, renderCell: (params) => {
            let selected = params.value ?? [];
            let selectedLabels = props.ssrCodeList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
            return selectedLabels;
        }},
        { field: 'HaulTypes', headerName: 'Haul Type', minWidth: 150, renderEditCell(params) {
            return (
                <Select
                    labelId="haul-type-checkbox-label"
                    id="haul-type-checkbox"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        let selectedLabels = props.haulTypeList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
                        //return (selected as any).join(', ')
                        return selectedLabels;
                    }}
                //MenuProps={MenuProps}
                >
                    {props.haulTypeList.filter(o => o.airline === params.row.airline).map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(type.value) > -1} onChange={e => {
                                if (e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list: string[] = [].concat(params.row.HaulTypes) ?? [];
                                    const idx = list.indexOf(type.value);

                                    if (idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    } else {
                                        list.push(type.value);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    }

                                }
                            }} />
                            <ListItemText primary={type.label} />
                        </MenuItem>
                    ))}
                </Select>
            )
        }, editable: true, renderCell: (params) => {
            let selected = params.value ?? [];
            let selectedLabels = props.haulTypeList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
            return selectedLabels;
        }},
        
        { field: 'AcRegs', headerName: 'Aircraft Reg.', minWidth: 150, renderEditCell(params) {
            return (
                <Select
                    labelId="acreg-checkbox-label"
                    id="acreg-checkbox"
                    multiple
                    fullWidth
                    value={params.value}
                    onChange={(e, c) => {
                        params.value = e.target.value
                    }
                    }
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        let selectedLabels = props.aircraftList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
                        return selectedLabels;
                    }}
                >
                    {props.aircraftList.filter(o => o.airline === params.row.airline).map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                            <Checkbox checked={(params.value ?? []).indexOf(type.value) > -1} onChange={e => {
                                if (e !== undefined) {
                                    const id = params.id;
                                    const field = params.field;

                                    const list: string[] = [].concat(params.row.AcRegs) ?? [];
                                    const idx = list.indexOf(type.value);

                                    if (idx !== -1) {
                                        list.splice(idx, 1);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    } else {
                                        list.push(type.value);
                                        apiRef.current.setEditCellValue({ id, field, value: list });
                                    }

                                }
                            }} />
                            <ListItemText primary={type.label} />
                        </MenuItem>
                    ))}
                </Select>
            )
        }, editable: true, renderCell: (params) => {
            let selected = params.value ?? [];
            let selectedLabels = props.aircraftList.filter(o => selected.some((i : string) => i === o.value)).map(o => o.label).join(', ');
            return selectedLabels;
        }},
        { field: 'actions', type: 'actions', headerName: '', renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                return (
                    <IconButton color="error" onClick={() => confirmDeletion(row as ServiceTypeFields & DynamoDbObject)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const data = useMemo(() => props.filteredData, [props.filteredData]);

    const handleRowModesModelChange = (model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
      };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        
    };

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        if(props.updateAction !== undefined) {
            props.updateAction(row as (ServiceTypeFields & DynamoDbObject)).then(() => {
                apiRef.current.updateRows([{ id: row.id, IsLoading: false }]);
            });
        } 
        
        return updatedRow;
    };
    
    const handleSaveClick = (item : ServiceTypeFields) => () => {
        setRowModesModel({...rowModesModel, [(item as any).id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteItem = () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined && props.deleteAction !== undefined) {
            apiRef.current.updateRows([{ id: (deleteItem as any).id, IsLoading: true }]);
            props.deleteAction(deleteItem).then(success => {
                if(success)
                    apiRef.current.updateRows([{ id: (deleteItem as any).id, _action: 'delete' }]);
                else
                    apiRef.current.updateRows([{ id: (deleteItem as any).id, IsLoading: false }]);
            });
        }
    };

    const confirmDeletion = (item : ServiceTypeFields & DynamoDbObject) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    return (
        <div>
        <StyledBox>
            <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={data}
            columns={columns}
            editMode="row"
            getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 1 ? `tui-grid-alternate-row` : ''}}
            loading={props.loading}
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
            rowModesModel={rowModesModel}
            onRowModesModelChange={(m, d) => handleRowModesModelChange(m, d)}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
        />
        </StyledBox>
        <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
            <DialogTitle id="alert-dialog-title">
                {"Delete Service Type?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Do you want to delete this item? You can't undo this action.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
            <Button onClick={handleDeleteItem} color="error">Delete</Button>
            </DialogActions>
      </Dialog>
        </div>
    )
}

export default ServiceTypeSsrGrid;