import { useState, useMemo, useCallback } from 'react'
import { API } from 'aws-amplify';
import { Paper, Button, CircularProgress, SvgIcon, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@mui/material';
// import { API } from 'aws-amplify'
import Grid from '@mui/material/Grid';
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { Status, useFetch } from '../../hooks/useFetch';
import { DynamoDbObject } from '@aviation/catering-common';
import { AircraftGroupFields, BoBPackagePlan, BobConfigurationFields, SsrCodeFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import {toast} from "react-toastify";
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import BobForecastCreatePackagePlanDialog from './BobForecastCreatePackagePlanDialog';
import { ulid } from 'ulidx';
import BobForecastPackagePlanDetails from './BoBForecastPackagePlanDetails';

export interface IBobForecastPackagePlansProps {
    open: boolean;
    clientCode: string;
    showCreateDialog : boolean;
    cancelAction : () => void;
    createAction : (item : BoBPackagePlan & DynamoDbObject) => void;
}

function BobForecastPackagePlans(props: IBobForecastPackagePlansProps) {
    const apiRef = useGridApiRef();
    
    const [refreshCounter] = useState(0);
    const { status, data = [] } = useFetch<Array<BoBPackagePlan & DynamoDbObject>>(`/api/masterdata/bobpackageplan/${props.clientCode}`,(response) => response.Items, refreshCounter);
    const { data: productData = [] } = useFetch<Array<SsrCodeFields & DynamoDbObject>>(`/api/masterdata/ssrcode/${props.clientCode}`);
    const { data: aircraftTypesData = [] } = useFetch<Array<string>>(`/api/masterdata/aircrafttype/`, o => o);
    const { data: aircraftGroupData = [] } = useFetch<Array<AircraftGroupFields>>(`/api/masterdata/aircraftgroup/`, o => o.Items);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<BobConfigurationFields & DynamoDbObject | undefined>(undefined);
    const [showDetailsAnchor, setShowDetailsAnchor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentParent, setCurrentParent] = useState<any>(undefined);

    const rowId = (row : any) => {
        return `${row.PK}#${row.RK}`;
    }
    
    const productList = useMemo(() => {
        return productData.filter(o => o.IsProduct === true).map(o => { return {label: (o.RK + ' - ' + o.Description ?? ''), value: o.RK } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [productData])

    const acTypeList = useMemo(() => {
        return aircraftTypesData.map(o => { return {label: o, value: o } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [aircraftTypesData])

    const acGroupList = useMemo(() => {
        return aircraftGroupData.map(o => { return {label: o.Name ?? '(Unkown)', value: o.Id ?? '' } }).sort((a,b) => a.label < b.label ? -1 : 1);
    }, [aircraftGroupData])

    const rows = useMemo(() => {
        if (!data) return [];
        const result = (data as any[]).reduce((a, b) => [
            ...a,
            {
                ...b,
                id: rowId(b),
            }
        ], []);

        return result;
    }, [data])

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };
    // const handleCopyItem = async (airline: string, dep: string, dest: string, packingPlan: string) => {
    //     setShowCopyDialogAnchor(false)
    //     setLoading(true)
     
    //     const init = {
    //         body: {
    //             SourcePK: currentParent.PK,
    //             SourceRK: currentParent.RK,
    //             TargetDep: dep,
    //             TargetDest: dest,
    //             PackingPlan: packingPlan ?? undefined
    //         },
    //         headers: {}
    //     };
    //     try {
    //         await API.post('api', `/api/masterdata/bobconfiguration/copy`, init);
    //         toast.success(`Bob Configuration copyied successfully`);
    //     } catch(e) {
    //         console.error(e);
    //         toast.error(`An error occurred while copying BoB configuration`);
    //         return false;
    //     } finally {
    //         setRefreshCounter(refreshCounter+1)
    //         setLoading(false)
    //     }
    // }

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            const init = {
                body: {},
                headers: {}
            };
    
            try {
                await API.del('api', `/api/masterdata/bobpackageplan/${props.clientCode}/${(deleteItem as any).Id}`, init);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                toast.success(`Packing plan deleted successfully.`);
                return true;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting packing plan`);
                return false;
            } finally {
                setDeleteItem(undefined);
            }
        }
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [data]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [data]);

    
    const updateItem = async(item : BoBPackagePlan & DynamoDbObject) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/bobpackageplan/${props.clientCode}/${(item as any).Id}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const processRowUpdate = (row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };
        
        updateItem({PK: row.PK, RK: row.RK, Id: row.Id, Name: row.Name}).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to BoB packing plan saved successfully.`);
            else
                toast.error(`An error occurred while saving packing plan`);
        })
        
        return updatedRow;
    }

    const createPackagePlan = (name : string) => {
        setLoading(true);

        const id = ulid();

        const item : BoBPackagePlan & DynamoDbObject = {
            PK: `${props.clientCode}#BobPackagePlan`,
            RK: id,
            Id: id,
            Name: name,
            Entries: [],
        }

        props.createAction(item);

        updateConfiguration(item).then((result : boolean) => {
            setLoading(false);

            if(result) {
                (item as any).id = rowId(item);
                apiRef.current.updateRows([item]);
                toast.success('BoB packing plan created successfully.');
            }
            else
                toast.error('An error occurred while creating BoB packing plan');
        });
    }

    const updateConfiguration = async (item : any) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/masterdata/bobpackageplan/${props.clientCode}/${(item as any).Id}`, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }

    const columns: GridColDef[] = [
        { field: 'Name', headerName: 'Name', minWidth: 200, editable: true},
        { field: 'Entries', headerName: 'Items', minWidth: 80, valueGetter: (params) => params.row.Entries.length, editable: false },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;
    
            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isLoading)
                if(!isInEditMode) return [
                    <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowDetailsAnchor(true); }}>
                        <SvgIcon component={EditIcon} inheritViewBox />
                    </IconButton>,
                    // <IconButton color="primary" onClick={() => { setCurrentParent(row); setShowCopyDialogAnchor(true); }}>
                    //     <SvgIcon component={ThumbnailsIcon} inheritViewBox />
                    // </IconButton>,
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                    
                ]
                else
                    return ( 
                        <IconButton color="error" onClick={() => confirmDeletion(row)}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </IconButton>
                    )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];
    return (
        <Grid item xs={12}>
            <Grid>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }} elevation={3}>
                    <StyledBox>
                    <DataGridPremium
                        apiRef={apiRef}
                        
                        autoHeight 
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        getRowClassName={(params) => { return params.row.Parent === false ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
                        loading={status === Status.Fetching || status === Status.Idle || loading}
                        isCellEditable={(params) => true }

                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        
                        groupingColDef={{
                            headerName: ' ',
                            valueGetter: (params: GridValueGetterParams) => '',
                            width: 50,
                            resizable: false,
                            hideable: false,                
                        }}
                    />
                    </StyledBox>
                    { props.showCreateDialog && (<BobForecastCreatePackagePlanDialog 
                        open={props.showCreateDialog} 
                        // selectedDestinations={apiRef.current.rows}
                        cancelAction={props.cancelAction} 
                        createAction={createPackagePlan}
                     />)}
                    {/* { currentParent && showCopyDialogAnchor && <BobForecastCopyDialog open={showCopyDialogAnchor} parent={currentParent} cancelAction={() => {setShowCopyDialogAnchor(false);}} copyAction={handleCopyItem} />} */}
                    <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete Configuration?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this packing plan? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
            { currentParent && <BobForecastPackagePlanDetails open={showDetailsAnchor} parent={currentParent} productList={productList} aircraftTypeList={acTypeList} aircraftGroupList={acGroupList} cancelAction={() => {setShowDetailsAnchor(false);}} updateAction={() => {}} />}
        </Grid>
    )
}

export default BobForecastPackagePlans;