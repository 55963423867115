import { useState, useMemo } from 'react'
import { Stack, Button, Box, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export interface IServiceTypeCreateDialogProps {
    open : boolean;
    type: ("CREW" | "SSR");
    airlineList : {label: string, value: string}[];
    cancelAction : () => void;
    createAction : (airline : string, type: ("CREW" | "SSR"), code : string) => void;
}

function ServiceTypeCreateDialog(props : IServiceTypeCreateDialogProps) {
    const { t } = useTranslation();
    
    const [airlineCode, setAirlineCode] = useState('');
    const [code, setCode] = useState('');
    
    const [airlineCodeError, setAirlineCodeError] = useState(true);
    const [codeError, setCodeError] = useState(true);

    const handleName = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value;
        setCode(value);
        setCodeError(value === undefined || value === '');
    }

    const handleAirlineCode = (value : string | undefined) => {
        setAirlineCode(value ?? '');
        setAirlineCodeError(value === undefined || value === '');
    }

    const hasErrors = useMemo(() => {
        return airlineCodeError || codeError;
    }, [airlineCodeError, codeError]);

    return (
        <div>
        <Dialog open={props.open} onClose={props.cancelAction}>
            <DialogTitle>Create Service Type</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the form to create a new service type.
                </DialogContentText>
                <Box>
                <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <InputLabel id="airline-label">Airline</InputLabel>
                        <Select
                            labelId="airline-label"
                            id="airline-select"
                            value={airlineCode}
                            error={airlineCodeError}
                            label="Type"
                            onChange={(e) => handleAirlineCode(e.target.value) }>
                                { props.airlineList && props.airlineList.map((o)=> (
                                    <MenuItem  key={o.value} value={o.value}>{o.label}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 200 }} size="small">
                        <TextField
                                    type="text"
                                    variant='outlined'
                                    color='secondary'
                                    label={t('Name')}
                                    onChange={e => handleName(e)}
                                    value={code}
                                    error={codeError}
                                    helperText={codeError ? 'Please enter a code' : ''}
                                    fullWidth
                                    size="small"
                        />
                    </FormControl>
                    
                </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelAction}>Cancel</Button>
                <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, props.type, code) }>Create</Button>
            </DialogActions>
      </Dialog>
      </div>
    )
}

export default ServiceTypeCreateDialog;