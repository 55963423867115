import { useState, useMemo } from 'react'
import { Stack, Button, Box, Checkbox, ListItemText, OutlinedInput, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export interface IMenuGridCreateDialog {
    open: boolean;
    airlineList: { label: string, value: string }[];
    serviceTypeList: { label: string, value: string, airline: string }[];
    haulTypeList: { label: string, value: string, airline: string }[];
    cancelAction: () => void;
    createAction: (airline: string, haulTypes: string[], serviceTypes: string[], sourceSsrCode : string, targetSsrCode : string, hourFrom : number, hourTo : number) => void;
}

function MenuGridCreateDialog(props: IMenuGridCreateDialog) {
    const [airlineCode, setAirlineCode] = useState('');
    const [loadingTypes, setLoadingTypes] = useState<string[]>([]);
    const [haulTypes, setHaulTypes] = useState<string[]>([]);
    const [hourFrom, setHourFrom] = useState(0);
    const [hourTo, setHourTo] = useState(12);
    const [sourceSsrCode, setSourceSsrCode] = useState<string>('');
    const [targetSsrCode, setTargetSsrCode] = useState<string>('');
    const [airlineCodeError, setAirlineCodeError] = useState(true);
    const [loadingTypesError, setLoadingTypesError] = useState(true);
    const [haulTypesError, setHaulTypesError] = useState(true);
    const [sourceSsrCodeError, setSourceSsrCodeError] = useState(true);
    const [targetSsrCodeError, setTargetSsrCodeError] = useState(true);

    const handleAirlineCode = (value: string | undefined) => {
        setAirlineCode(value ?? '');
        setAirlineCodeError(value === undefined || value === '');
    }

    const handleSourceSsr = (value: string | undefined) => {
        setSourceSsrCode(value ?? '');
        setSourceSsrCodeError(value === undefined || value === '');
    }

    const handleTargetSsr = (value: string | undefined) => {
        setTargetSsrCode(value ?? '');
        setTargetSsrCodeError(value === undefined || value === '');
    }

    const hasErrors = useMemo(() => {
        return airlineCodeError || loadingTypesError || sourceSsrCodeError || targetSsrCodeError;
    }, [airlineCodeError, loadingTypesError, sourceSsrCodeError, targetSsrCodeError]);

    return (
        <div>
            <Dialog open={props.open} onClose={props.cancelAction}>
                <DialogTitle>Create menu grid</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill out the form to create a new menu grid. A menu grid will translate a generic meal code into specific codes.
                    </DialogContentText>
                    <Box>
                        <Stack spacing={2} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop: 2 }}>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <InputLabel id="airline-label">Airline</InputLabel>
                                <Select
                                    labelId="airline-label"
                                    id="airline-select"
                                    value={airlineCode}
                                    error={airlineCodeError}
                                    label="Type"
                                    onChange={(e) => handleAirlineCode(e.target.value)}>
                                    {props.airlineList && props.airlineList.map((o) => (
                                        <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <InputLabel id="haulTypes-label">Haul Types</InputLabel>
                                <Select
                                    labelId="loadingTypes-label"
                                    id="haulTypes-checkbox"
                                    multiple
                                    fullWidth
                                    error={haulTypesError}
                                    value={haulTypes}
                                    onChange={(e, c) => {

                                    }
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => (selected as any).join(', ')}
                                //MenuProps={MenuProps}
                                >
                                    {props.haulTypeList.filter(o => {
                                        return o.airline === airlineCode
                                    }).map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            <Checkbox checked={(haulTypes ?? []).indexOf(type.value) > -1} onChange={e => {
                                                if (e !== undefined) {
                                                    let list: string[] = [];
                                                    list = list.concat(haulTypes);
                                                    const idx = list.indexOf(type.value);

                                                    if (idx !== -1) {
                                                        list.splice(idx, 1);
                                                        setHaulTypes(list);
                                                        setHaulTypesError(list.length === 0);
                                                    } else {
                                                        list.push(type.value);
                                                        setHaulTypes(list);
                                                        setHaulTypesError(list.length === 0);
                                                    }

                                                }
                                            }} />
                                            <ListItemText primary={type.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 200 }} size="small">
                                <InputLabel id="loadingTypes-label">Service Types</InputLabel>
                                <Select
                                    labelId="loadingTypes-label"
                                    id="loadingTypes-checkbox"
                                    multiple
                                    fullWidth
                                    error={loadingTypesError}
                                    value={loadingTypes}
                                    onChange={(e, c) => {

                                    }
                                    }
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => (selected as any).join(', ')}
                                //MenuProps={MenuProps}
                                >
                                    {props.serviceTypeList.filter(o => {
                                        return o.airline === airlineCode
                                    }).map((serviceType) => (
                                        <MenuItem key={serviceType.value} value={serviceType.value}>
                                            <Checkbox checked={(loadingTypes ?? []).indexOf(serviceType.value) > -1} onChange={e => {
                                                if (e !== undefined) {
                                                    let list: string[] = [];
                                                    list = list.concat(loadingTypes);
                                                    const idx = list.indexOf(serviceType.value);

                                                    if (idx !== -1) {
                                                        list.splice(idx, 1);
                                                        setLoadingTypes(list);
                                                        setLoadingTypesError(list.length === 0);
                                                    } else {
                                                        list.push(serviceType.value);
                                                        setLoadingTypes(list);
                                                        setLoadingTypesError(list.length === 0);
                                                    }
                                                }
                                            }} />
                                            <ListItemText primary={serviceType.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Stack spacing={{ sm: 2 }} direction="row" useFlexGap>
                                <FormControl sx={{ minWidth: 220 }}>
                                    <TextField id="sourceSsr-input" label="SSR Code" size="small" value={sourceSsrCode} 
                                    onChange={(e) => handleSourceSsr(e.target.value)} error={sourceSsrCodeError}
                                    helperText={sourceSsrCodeError ? 'Please enter a source SSR code that should get translated' : ''}/>
                                </FormControl>
                                <FormControl sx={{ minWidth: 220 }}>
                                    <TextField id="targetSsr-input" label="Target Meal Code" size="small" value={targetSsrCode} 
                                    onChange={(e) => handleTargetSsr(e.target.value)} error={targetSsrCodeError}
                                    helperText={targetSsrCodeError ? 'Please enter a meal code we should translate to.' : ''}/>
                                </FormControl>
                            </Stack>
                            <Stack spacing={{ sm: 2 }} direction="row" useFlexGap>
                                <FormControl sx={{ minWidth: 220 }}>
                                    <TextField id="hourFrom-input" label="From (Hour)" type='number' size="small" value={hourFrom} 
                                        onChange={(e) => setHourFrom(parseInt(e.target.value))} />
                                </FormControl>
                                <FormControl sx={{ minWidth: 220 }}>
                                    <TextField id="hourTo-input" label="To (Hour)" type='number' size="small" value={hourTo} 
                                        onChange={(e) => setHourTo(parseInt(e.target.value))} />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.cancelAction}>Cancel</Button>
                    <Button disabled={hasErrors} onClick={(e) => props.createAction(airlineCode, haulTypes, loadingTypes, sourceSsrCode, targetSsrCode, hourFrom, hourTo)}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MenuGridCreateDialog;