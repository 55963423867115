import { useState, useEffect, ChangeEvent } from 'react'
import { Drawer, Box, Stack, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
// import { API } from 'aws-amplify'
import { SourceMarketFields, UserClientInfo, UserFields } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import BoxHeader from '../../common/Components/BoxHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { useFetch } from '../../hooks/useFetch';
import { useAuth } from '../../hooks/useAuth';

dayjs.extend(utc)

export interface IUserClientListProps {
    open: boolean;
    parent: UserFields;
    cancelAction: () => void;
    updateAction: (user : UserFields) => void;
}

function UserClientList(props : IUserClientListProps) {
    const { data } = useFetch<Array<SourceMarketFields>>(`/api/masterdata/sourcemarkets`);
    const [clientList, setClientList] = useState<Array<UserClientInfo>>([]);
    const auth = useAuth(); 
    
    useEffect(() => {
        setClientList(props.parent.Clients ?? []);
    }, [props.parent.Clients]);

    const cancel = () => {
        props.cancelAction();
    }

    const onChange = (clientCode : string, clientName : string, event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if(props.parent.Clients === undefined) {
            props.parent.Clients = [];
        }

        let existing = clientList.find(o => o.ClientCode === clientCode);

        if(checked && !existing) {
            let clientInfo : UserClientInfo = {
                ClientCode: clientCode,
                ClientName: clientName,
                Username: props.parent.Username
            }

            let list = [...clientList, clientInfo];
            props.parent.Clients = list;
            setClientList(list);

            if(props.parent !== undefined)
                props.updateAction(props.parent!);
        } else if(!checked && existing) {
            props.parent.Clients = props.parent.Clients?.filter(o => o.ClientCode !== clientCode);
            setClientList(props.parent.Clients);

            if(props.parent !== undefined)
                props.updateAction(props.parent!);
        }
    }

    return (
        <Drawer anchor='right' open={props.open} onClose={() => cancel()} style={{maxWidth:'50%'}}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Source Markets'}>
                    
                </BoxHeader>
                <p>Enable these source markets, the user is allowed to see respectively maintain.</p>
            </Box>
            
           <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <Stack spacing={0} direction="column" sx={{ marginBottom: 4, marginTop: 1, paddingTop:2 }}>
                    { data?.map((o : SourceMarketFields) => 
                        <FormGroup>
                            <FormControlLabel control={<Checkbox disabled={!auth.isSuperAdmin} value={o.ClientName ?? 'Unkown'} onChange={(e, c) => onChange(o.ClientCode ?? '', o.ClientName ?? 'Unkown', e, c)} 
                            checked={
                                clientList.find(m => m.ClientCode === o.ClientCode) !== undefined
                                } />} label={o.ClientName} />
                        </FormGroup> 
                    )} 
                </Stack>
            </StyledBox>
        </Drawer>
    )
}

export default UserClientList